<template>
  <section id="contentsAward">
    <b-form @submit.prevent="updateContents">
      <b-row class="match-height mt-2">
        <b-col>
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('resFunciones.awardSystemActive.title') }}</b-card-title>

              <b-button
                type="submit"
                variant="success"
              >
                {{
                  $t("dataGeneric.save")
                }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-overlay
                    variant="transparent"
                    :show="show"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('awardSystem.totalPointsAwards')"
                        >
                          <b-form-input
                            v-model="totalPointsAwards"
                            placeholder="0"
                            type="number"
                            maxlength="80"
                            class="mr-1"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('awardSystem.numFlagsAwards')"
                        >
                          <b-form-input
                            v-model="numFlagsAwards"
                            placeholder="1"
                            class="mr-1"
                            maxlength="180"
                            min="1"
                            @keydown="preventZero"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormInput,
  BOverlay,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'

import { showToast, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BForm,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    code: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      silverAward: null,
      totalPointsAwards: null,
      numFlagsAwards: null,
      show: false,
      id: null,

    }
  },
  mounted() {
    this.getDataContent()
  },
  methods: {
    preventZero(event) {
    // Evita que el usuario presione "0" si el campo está vacío
      if (event.key === '0' && (!this.numFlagsAwards || this.numFlagsAwards === '0')) {
        event.preventDefault()
      }
    },

    getDataContent() {
      axios
        .post('', {
          query: `query{
            allContents(id:"${this.code}"){
              edges {
                node {
                  totalPointsAwards
                  numFlagsAwards
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allContents.edges[0]
          this.id = response.node.id
          this.totalPointsAwards = response.node.totalPointsAwards
          this.numFlagsAwards = response.node.numFlagsAwards && response.node.numFlagsAwards > 0 ? response.node.numFlagsAwards : 1
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateContents(event) {
      this.show = true
      event.preventDefault()
      const query = `
            mutation{
            updateContents(id:"${this.code}",input:{
              totalPointsAwards:${this.totalPointsAwards},
              numFlagsAwards:${this.numFlagsAwards},

            }) {
              content{
                name
                id
              }
            }
          }
        `

      axios.post('', { query })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.show = false
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
  },
}
</script>
<style lang="scss" scoped></style>
