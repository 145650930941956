<template>
  <div style="align-content: space-evenly;">
    <!-- <div class="circular-menu mr-2">
      <b-button
        class="main-btn btn-gradient"
        @click="toggleMenu"
      >
        <b-img
          :src="aiWhite"
          rounded
          width="25px"
          class="mr-50"
        />
      </b-button>

      <div
        v-for="(option, index) in options"
        :key="index"
        :style="getOptionStyle(index)"
        class="menu-option"
        :class="{ 'visible': isOpen }"
        @click="handleOptionClick(option)"
      >
        {{ option }}
      </div>
    </div> -->
    <b-dropdown
      v-if="hasAi"
      id="cpuBut"
      v-b-tooltip.hover.top="disabled ? $t('IA.infoDis') : ''"
      class="btn-gradient mr-1"
      variant="outline-primary"
      :disabled="disabled"
    >
      <template #button-content>
        <b-img
          :src="aiWhite"
          rounded
          width="18px"
          class="mr-50"
        />
        <span>
          {{ $t('IA.title') }}
        </span>
      </template>
      <b-dropdown-item
        v-if="view == 'info' && language == base"
        class="custom-dropdown-item"
        @click="openChatAI(6)"
      >
        {{ $t('IA.shortDescription') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'info' && language == base"
        class="custom-dropdown-item"
        @click="openChatAI(0)"
      >
        {{ $t('IA.description') }}
      </b-dropdown-item>

      <b-dropdown-item
        v-if="view == 'image'"
        class="custom-dropdown-item"
        @click="openChatAI('imageFolder')"
      >
        {{ $t('IA.imageFolder') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'image'"
        class="custom-dropdown-item"
        @click="openChatAI('imageBackground')"
      >
        {{ $t('IA.imageBackground') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'image'"
        class="custom-dropdown-item"
        @click="openChatAI('imageMobile')"
      >
        {{ $t('IA.imageMobile') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'image'"
        class="custom-dropdown-item"
        @click="openChatAI('imageBackgroundMobile')"
      >
        {{ $t('IA.imageBackgroundMobile') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'image'"
        class="custom-dropdown-item"
        @click="openChatAI('imageAlternative')"
      >
        {{ $t('IA.imageAlternative') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'image'"
        class="custom-dropdown-item"
        @click="openChatAI('imageTitle')"
      >
        {{ $t('IA.imageTitle') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'moments'"
        class="custom-dropdown-item"
        @click="openChatAI(7)"
      >
        {{ $t('IA.moments') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'seo' && language == base"
        class="custom-dropdown-item"
        @click="openChatAI(3)"
      >
        {{ $t('IA.seoTitle') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view == 'seo' && language == base"
        class="custom-dropdown-item"
        @click="openChatAI(4)"
      >
        {{ $t('IA.seoDescription') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="view != 'image' && language != base"
        class="custom-dropdown-item"
        @click="callToTranslate()"
      >
        {{ $t('IA.translate') }}
      </b-dropdown-item>
    </b-dropdown>
    <b-modal
      id="customModal"
      ref="ia-modal"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
      content-class="custom-modal-content"
    >
      <div class="d-block">
        <b-card-header>
          <b-card-title>{{ $t('developIa') }}</b-card-title>
          <span class="text-muted">{{ $t('InfoIa') }}</span>
        </b-card-header>

        <b-card-body>
          <b-overlay
            :show="loading"
            variant="transparent"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
          >
            <div
              v-if="!isEmpty(shortText)"
              class="mb-2"
            >
              <label for="">{{ $t('category.shortDescription') }}</label>
              <div class="contenedor">
                <div class="elemento">
                  <b-form-input
                    v-model="typedShortText"
                    class="text-area"
                    disabled
                    :placeholder="$t('generateText')"
                  />
                </div>
              </div>
            </div>

            <div v-if="view == 'image'">
              <b-row style="justify-content: space-around;">
                <b-col
                  v-for="(imageAI, index) in imageAIArray"
                  :key="index"
                  md="4"
                  sm="6"
                  xs="12"
                  @click="selectImage(imageAI, 'pexel_' + index)"
                >
                  <div
                    :id="'pexel_' + index"
                    :key="index"
                    class="image-container"
                  >
                    <b-img
                      :src="imageAI"
                      alt="Imagen generada por AI"
                      fluid
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
            <transition
              v-else
              name="fade"
              tag="div"
            >
              <div>
                <label
                  v-if="!isEmpty(shortText)"
                  for=""
                >{{ $t('dataGeneric.description') }}</label>

                <div class="contenedor">
                  <div class="elemento">
                    <b-form-textarea
                      ref="typedText"
                      v-model="typedText"
                      class="text-area"
                      rows="7"
                      cols="10"
                      disabled
                      :placeholder="$t('generateText')"
                    />
                  </div>
                </div>
              </div>
            </transition>
          </b-overlay>

          <div class="d-flex justify-content-end mt-3">
            <b-button
              variant="success"
              :disabled="view !== 'image' ? loading : isEmpty(objectResult)"
              @click="sendData()"
            >
              {{ $t('aplicar') }}
            </b-button>
          </div>
        </b-card-body>
      </div>
    </b-modal>
    <b-modal
      ref="translate-modal"
      scrollable
      size="lg"
      no-close-on-backdrop
      hide-footer
      content-class="custom-modal-content"
    >
      <div class="d-block">
        <b-card-header>
          <b-card-title>{{ $t('developIa') }}</b-card-title>
          <span class="text-muted">{{ $t('InfoIa') }}</span>
        </b-card-header>
        <b-card-body>
          <b-overlay
            :show="loading"
            variant="transparent"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
          >
            <transition
              v-for="trns in viewTranslate"
              :key="trns.id"
              name="fade"
              tag="div"
            >
              <div class="">
                <b-row class="mb-2 mt-2">
                  <b-col>
                    <label for="">{{ $t(trns.id) }}</label>
                  </b-col>
                  <b-col
                    class="align-content-end "
                    style="text-align: end"
                  >
                    <b-button
                      size="sm"
                      class="buttom-AI"
                      @click="applyFields(trns)"
                    >
                      {{ $t('aplicar') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="mb-1 mt-1">
                  <b-col>
                    <div class="contenedor">
                      <div class="elemento">
                        <b-form-textarea
                          :id="'trns_' + trns.id"
                          :ref="'trns_' + trns.id"
                          class="text-area"
                          rows="3"
                          cols="5"
                          disabled
                          :placeholder="$t('generateText')"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </transition>
          </b-overlay>
          <div class="d-flex justify-content-end mt-3">
            <b-button
              :disabled="loading"
              variant="success"
              @click="applyAll()"
            >
              {{ $t('aplicarTodo') }}
            </b-button>
          </div>
        </b-card-body>
      </div>
    </b-modal>
    <!-- GENERATE MOMENT MODAL -->
    <b-modal
      id="momentModal"
      ref="ai-moment-modal"
      centered
      size="xl"
      no-close-on-backdrop
      hide-footer
      content-class="custom-modal-content"
    >
      <div class="d-block">
        <b-card-header>
          <b-card-title>{{ $t('developIa') }}</b-card-title>
          <span class="text-muted">{{ $t('InfoIa') }}</span>
        </b-card-header>
        <b-card-body>
          <b-overlay
            :show="showMoment"
            variant="transparent"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
          >
            <b-row>
              <b-col
                md="8"
                align-self="baseline"
                class="
                            embed-responsive
                            embed-responsive-16by9
                            mr-1"
              >
                <video
                  id="ai-video"
                  controls
                  class="video-js vjs-default-skin vjs-big-play-centered embed-responsive-item "
                  data-setup="{&quot;liveui&quot;: true}"
                  crossOrigin="dashboard.interactvty.com"
                >

                  <source
                    :src="fileUrl"
                    :type="fileUrl.includes('m3u8')
                      ? 'application/x-mpegURL'
                      : fileUrl.includes('mp4')
                        ? 'video/mp4'
                        : 'audio/mp3'
                    "
                  >
                </video>
              </b-col>
              <b-col class="first-col-scroll">
                <b-row
                  v-for="scene in scenes"
                  :key="scene.id"
                  class="mb-3"
                >
                  <b-col>
                    <video
                      :id="scene.id"
                      class="video-js vjs-default-skin vjs-big-play-centered tamanio cursor-pointer w-100"
                      data-setup="{&quot;liveui&quot;: true}"
                      preload="metadata"
                      crossOrigin="dashboard.interactvty.com"
                      :src="fileUrl"
                      :poster="scene.thumbnail"
                      @loadedmetadata="setVideoMoment(scene, scene.instances[0].start)"
                      @click.stop="goToMoment(scene.instances[0].start)"
                    />
                  </b-col>

                  <b-col class="colInfo">
                    <div>
                      <span
                        class="cursor-pointer"
                        @click="goToMoment(scene.instances[0].start)"
                      >
                        {{ scene.instances[0].start }}
                      </span>
                      <span>
                        -
                      </span>
                      <span
                        class="cursor-pointer"
                        @click="goToMoment(scene.instances[0].end)"
                      >
                        {{ scene.instances[0].end }}
                      </span>
                    </div>
                    <div>
                      <b-form-input
                        :id="'#' + scene.id"
                        :value="'#' + scene.id+' '+$t('IA.generatedBy')"
                        :placeholder="$t('title')"
                        required
                      />
                    </div>
                    <div class="d-flex  mb-1">
                      <b-button
                        size="sm"
                        class="buttom-AI"
                        @click="generate(scene)"
                      >
                        {{ $t('GenMomento') }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <!-- <div class="d-flex justify-content-end mt-3">
              <b-button
                variant="success"
                :disabled="loading"
                @click="sendData()"
              >
                {{ $t('aplicar') }}
              </b-button>
            </div> -->
          </b-overlay>
        </b-card-body>
      </div>
    </b-modal>
    <b-modal
      ref="loadingModal"
      centered
      :title="$t('loading')"
      no-close-on-backdrop
      hide-footer
      content-class="custom-modal-content"
    >
      <div class="text-center">
        <b-spinner
          variant="primary"
          :label="$t('loading')"
        />
        <br>
        <b-progress
          :value="progress"
          max="100"
          class="mt-3"
          animated
          variant="success"
        />
        <p class="mt-2">
          {{ progress }}%
        </p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormTextarea,
  BSpinner,
  BProgress,
  BButton,
  BCol,
  BImg,
  BCardHeader, VBTooltip,
  BDropdown,
  BRow,
  BDropdownItem,
  BCardTitle,
  BOverlay,
  BCardBody,
  BFormInput,
} from 'bootstrap-vue'
import {
  searchImagesInPexels, getOpenAICompletion, showToast,
  isEmpty,
  procesarVideo,
  utf8ToB64,
  messageError,
} from '@/store/functions'
import localesLanguages from '@core/utils/languageModels'

import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

const aiWhite = require('@/assets/images/icons/ai_blanco.png')
const aiColor = require('@/assets/images/icons/ai.png')

export default {
  components: {
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BSpinner,
    BProgress,
    BCol,
    BRow,
    BCardBody,
    BImg,
    BOverlay,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,

    },
    view: {
      type: String,
      required: true,
    },
    fileUrl: {
      type: String,
      default: '',

    },
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
    callParentMethod: Function,

  },
  data() {
    return {
      aiWhite,
      showMoment: false,
      userData: getUserData(),
      defaultLanguage: '',
      typeImage: '',
      isOpen: false, // Estado del menú desplegable
      options: ['Opción 1', 'Opción 2', 'Opción 3', 'Opción 4', 'Opción 5', 'Opción 6'],
      promt: 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al título y descripción corta de un contenido y debes proporcionarme una descripción más larga. La primera frase es el título de un programa o contenido. Sólo quiero que me respondas la descripción larga. Damelo en el mismo idioma que tenga la descripción corta.',
      imageAIArray: [],
      fullText: '',
      typedText: '',
      typingSpeed: 10,
      loading: false,
      shortText: '',
      typedShortText: '',
      objectResult: {},
      typed: 0,
      viewTranslate: [],
      scenes: [],
      progress: 0,
      hasAi: false,
      localesLanguages,
      dataToTranslate: null,
    }
  },

  mounted() {
    // const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    const { hasAi } = this.userData?.profile?.client
    if (hasAi) {
      this.hasAi = true
      this.getDefaultLanguage()
      this.insertImage()
    } else {
      this.hasAi = false
    }
  },

  methods: {
    insertImage() {
      let elemento = ''
      let labels = []
      switch (this.view) {
        case 'info':
          labels = ['descriptionLabel', 'nameLabel', 'shortDescriptionLabel', 'technicalDetailsLabel']
          break
        case 'image':
          break
        case 'seo':
          labels = ['seoTitleLabel', 'seoDescriptionLabel']
          break
        default:
          break
      }
      const showInSpecificLabels = this.language === this.base

      labels.forEach(label => {
        elemento = document.getElementById(label)
        if (elemento) {
          const existingImage = elemento.querySelector('img.ai-image')

          if (!existingImage) {
            if (showInSpecificLabels && this.isAllowedLabel(label)) {
              const imgElement = this.createImageElement(label)
              elemento.appendChild(imgElement)
            } else if (!showInSpecificLabels) {
              const imgElement = this.createImageElement(label)
              elemento.appendChild(imgElement)
            }
          }
        }
      })
    },
    isAllowedLabel(label) {
      const allowedLabels = ['descriptionLabel', 'shortDescriptionLabel', 'seoTitleLabel', 'seoDescriptionLabel']
      return allowedLabels.includes(label)
    },
    createImageElement(label) {
      const imgElement = document.createElement('img')
      imgElement.src = aiColor
      imgElement.style.width = '18px'
      imgElement.style.borderRadius = '50%'
      imgElement.classList.add('ml-2', 'cursor-pointer', 'ai-image')
      imgElement.addEventListener('click', () => this.AIEvent(label))

      return imgElement
    },
    AIEvent(label) {
      if (this.language === this.base) {
        switch (label) {
          case 'descriptionLabel':
            this.openChatAI(0)
            break
          case 'shortDescriptionLabel':
            this.openChatAI(6)

            break
          case 'seoTitleLabel':
            this.openChatAI(3)
            break
          case 'seoDescriptionLabel':
            this.openChatAI(4)
            break
          default:
            break
        }
      } else {
        this.callToTranslate()
      }
    },
    async callToTranslate() {
      const result = await this.callParentMethod(this.view)
      if (result) {
        this.dataToTranslate = result
        this.openChatAI(5)
      }
    },
    isEmpty,
    toggleMenu() {
      this.isOpen = !this.isOpen // Alternar el estado del menú
    },
    getOptionStyle(index) {
      const angle = (index / this.options.length) * 2 * Math.PI // Ángulo de cada opción
      const radius = 100 // Radio del círculo
      const x = radius * Math.cos(angle)
      const y = radius * Math.sin(angle)

      return {
        transform: this.isOpen ? `translate(${x}px, ${y}px)` : 'translate(0px, 0px)', // Posicionamiento circular solo si isOpen es true
        transition: `transform 0.5s ease ${index * 0.1}s`, // Retrasamos cada opción escalonadamente
      }
    },
    handleOptionClick(option) {
      alert(`Has seleccionado: ${option}`) // Acción cuando se hace clic en una opción
    },

    applyAll() {
      const objectResult = {}
      this.viewTranslate.forEach(element => {
        objectResult[element.id] = element.fullText
      })
      this.$emit('updateData', objectResult)
      this.$refs['translate-modal'].hide()
    },
    applyFields(data) {
      this.$emit('updateData', { [data.id]: data.fullText })
    },

    setVideoMoment(scene, time) {
      const video = document.getElementById(scene.id) // Obtener el video por su id

      // Convertir el tiempo (en formato "0:00:12.92") a segundos
      const timeParts = time.split(':').map(Number)
      const seconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2]

      video.currentTime = seconds // Establecer el tiempo del video

      video.addEventListener('seeked', () => {
        // Crear un canvas para capturar el frame en el tiempo especificado
        try {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const ctx = canvas.getContext('2d')

          // Dibujar el frame en el canvas
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

          // Convertir el frame en una URL base64 (imagen)
          const thumbnail = canvas.toDataURL('image/jpeg')

          // Asignar la miniatura al objeto scene
          // eslint-disable-next-line no-param-reassign
          scene.thumbnail = thumbnail

          // Pausar el video para que no se reproduzca
          video.pause()
          // eslint-disable-next-line no-empty
        } catch (error) {
        }
      })
    },
    goToMoment(time) {
      const videoElement = document.getElementById('ai-video')

      if (videoElement) {
        const timeParts = time.split(':') // Por ejemplo: "0:00:12.92"
        const seconds = parseInt(timeParts[0], 10) * 3600 + parseInt(timeParts[1], 10) * 60 + parseFloat(timeParts[2])
        videoElement.currentTime = seconds
      }
    },
    async openChatAI(type = 0) {
      this.typed = type
      this.loading = true
      this.fullText = ''
      this.typedText = ''
      this.imageAIArray = []
      this.shortTextText = ''
      this.typeImage = ''
      this.typedShortText = ''
      this.objectResult = {}
      const { title, description, longDescription } = this.item
      const texto = `${title}. ${description}`
      let translate = ''
      let { promt } = this
      let textImage = ''
      let interval = null
      let completedCalls = 0
      let keys = []
      let totalKeys = 0
      let insightVideo = ''
      const videoUrl = 'https://fcb-cdn-global.interactvty.net/VIDEOS/206497/mp4/VO240731A50373_-_HIGHLIGHTS_BAR_A_MAN_CITY_GIRA_2024-surround.mp4'
      switch (type) {
        case 6:
          this.$refs['ia-modal'].show()

          promt = 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al título de un contenido y debes proporcionarme una descripción corta con un máximo de 180 caracteres. Damelo en el mismo idioma que el texto introducido'
          getOpenAICompletion(texto, promt)
            .then(result => {
              this.fullText = result
              this.objectResult = { ...this.objectResult, shortDescription: this.fullText }
              this.typeText('typedText', this.fullText, this.typingSpeed)
              this.loading = false
            }).catch(error => {
              showToast(`Error al obtener la respuesta de OpenAI:${error}`, 2, this)

              this.$swal({
                title: this.$t('Error'),
                text: this.$t('IA.error'),
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: this.$t('confirm'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$refs['ia-modal'].hide()

              console.error('Error al obtener la respuesta de OpenAI:', error)
            })

          break
        // Texto, obtener descripción
        case 0:
          this.$refs['ia-modal'].show()
          if (!isEmpty(description)) {
            promt = 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al título y descripción corta de un contenido y debes proporcionarme una descripción más larga. La primera frase es el título de un programa o contenido. Sólo quiero que me respondas la descripción larga. Damelo en el mismo idioma que tenga la descripción corta.'
            getOpenAICompletion(texto, promt)
              .then(result => {
                this.fullText = result
                this.objectResult = { description: this.fullText }
                this.typeText('typedText', this.fullText, this.typingSpeed)
                this.loading = false
              })
              .catch(error => {
                showToast(`Error al obtener la respuesta de OpenAI:${error}`, 2, this)

                this.$swal({
                  title: this.$t('Error'),
                  text: this.$t('IA.error'),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonText: this.$t('confirm'),
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.$refs['ia-modal'].hide()

                console.error('Error al obtener la respuesta de OpenAI:', error)
              })
          } else {
            promt = 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al título de un contenido y debes proporcionarme una descripción corta con un máximo de 180 caracteres. Damelo en el mismo idioma que el texto introducido'
            getOpenAICompletion(texto, promt)
              .then(result => {
                this.shortText = result
                this.objectResult = { ...this.objectResult, shortDescription: this.shortText }
                this.typeText('typedShortText', this.shortText, this.typingSpeed)

                promt = 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al título y descripción corta de un contenido y debes proporcionarme una descripción más larga. La primera frase es el título de un programa o contenido. Sólo quiero que me respondas la descripción larga. Damelo en el mismo idioma que tenga la descripción corta.'
                getOpenAICompletion(`${title}. ${result}`, promt)
                  .then(res => {
                    this.fullText = res
                    this.objectResult = { ...this.objectResult, description: this.fullText }
                    this.typeText('typedText', this.fullText, this.typingSpeed)
                    this.loading = false
                  })
                  .catch(error => {
                    showToast(`Error al obtener la respuesta de OpenAI:${error}`, 2, this)
                    this.$swal({
                      title: this.$t('Error'),
                      text: this.$t('IA.error'),
                      icon: 'error',
                      showCancelButton: false,
                      confirmButtonText: this.$t('confirm'),
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                    this.$refs['ia-modal'].hide()
                    console.error('Error al obtener la respuesta de OpenAI:', error)
                  })
              })
              .catch(error => {
                showToast(`Error al obtener la respuesta de OpenAI:${error}`, 2, this)

                this.$swal({
                  title: this.$t('Error'),
                  text: this.$t('IA.error'),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonText: this.$t('confirm'),
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.$refs['ia-modal'].hide()

                console.error('Error al obtener la respuesta de OpenAI:', error)
              })
          }
          break
        // SEO Title
        case 3:
          if (!isEmpty(description) || !isEmpty(title)) {
            this.$refs['ia-modal'].show()
            promt = 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al título y descripción corta de un contenido y debes proporcionarme un título de seo con un máximo de 80 caracteres. Damelo en el mismo idioma del texto que te he dado'
            getOpenAICompletion(texto, promt)
              .then(result => {
                this.fullText = result
                this.objectResult = { seoTitle: this.fullText }
                this.typeText('typedText', this.fullText, this.typingSpeed)
                this.loading = false
              })
              .catch(error => {
                showToast(`Error al obtener la respuesta de OpenAI:${error}`, 2, this)

                this.$swal({
                  title: this.$t('Error'),
                  text: this.$t('IA.error'),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonText: this.$t('confirm'),
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.$refs['ia-modal'].hide()
                console.error('Error al obtener la respuesta de OpenAI:', error)
              })
          } else {
            this.$swal({
              title: this.$t('Error'),
              text: this.$t('IA.fillField'),
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: this.$t('confirm'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          break
        // SEO Description
        case 4:
          if (!isEmpty(description) || !isEmpty(title)) {
            this.$refs['ia-modal'].show()
            promt = 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al título y descripción corta de un contenido y debes proporcionarme una descripción de seo con un máximo de 180 caracteres. Dámelo en el mismo idioma del texto que te he dado'
            getOpenAICompletion(texto, promt)
              .then(result => {
                this.fullText = result
                this.objectResult = { seoDescription: this.fullText }
                this.typeText('typedText', this.fullText, this.typingSpeed)
                this.loading = false
              })
              .catch(error => {
                showToast(`Error al obtener la respuesta de OpenAI:${error}`, 2, this)

                this.$swal({
                  title: this.$t('Error'),
                  text: this.$t('IA.error'),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonText: this.$t('confirm'),
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.$refs['ia-modal'].hide()
                console.error('Error al obtener la respuesta de OpenAI:', error)
              })
          } else {
            this.$swal({
              title: this.$t('Error'),
              text: this.$t('IA.fillField'),
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: this.$t('confirm'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          break
        // Traducir vista
        case 5:
          promt = `Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar un texto y debes proporcionarme la traducción exacta a ${this.getNameByLocale(this.language)}.`
          translate = ''
          this.viewTranslate = []
          this.$refs['translate-modal'].show()
          keys = Object.keys(this.dataToTranslate)
          completedCalls = 0
          totalKeys = keys.length
          keys.forEach(clave => {
            translate = this.dataToTranslate[clave]
            if (!isEmpty(translate)) {
              getOpenAICompletion(translate, promt)
                .then(result => {
                  this.viewTranslate.push({
                    id: clave, fullText: result,
                  })
                  this.typeTextObject(clave, result, this.typingSpeed)
                  completedCalls += 1

                  if (completedCalls === totalKeys) {
                    this.loading = false
                  }
                })
                .catch(error => {
                  showToast(`Error al obtener la respuesta de OpenAI:${error}`, 2, this)
                  this.$swal({
                    title: this.$t('Error'),
                    text: this.$t('IA.error'),
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: this.$t('confirm'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                  completedCalls += 1

                  if (completedCalls === totalKeys) {
                    this.loading = false
                  }
                  console.error('Error al obtener la respuesta de OpenAI:', error)
                })
            } else {
              completedCalls += 1

              if (completedCalls === totalKeys) {
                this.loading = false
              }
            }
          })

          break
        case 7:
          this.$refs.loadingModal.show()
          this.progress = 0
          interval = setInterval(() => {
            if (this.progress < 90) {
              this.progress += 10 // Aumentar el progreso
            }
          }, 500)
          insightVideo = await procesarVideo(videoUrl, `#${this.$route.params.id}`)
          clearInterval(interval)
          this.progress = 100
          // Mostrar resultados
          if (insightVideo) {
            console.log('Datos generados por la IA sobre el video:')
            this.$refs.loadingModal.hide()
            this.$refs['ai-moment-modal'].show()
            console.log(insightVideo)
            this.scenes = insightVideo.videos[0].insights.scenes
          }
          break
        // Todas las imagenes
        default:
          if (!isEmpty(description)) {
            if (!isEmpty(longDescription)) {
              if (description.length > longDescription.length) {
                textImage = description
              } else {
                textImage = longDescription
              }
            } else {
              textImage = description
            }
            this.$refs['ia-modal'].show()
            searchImagesInPexels(textImage).then(result => {
              if (isEmpty(result)) {
                this.$refs['ia-modal'].hide()

                this.$swal({
                  title: this.$t('code.atention'),
                  text: this.$t('IA.imageNotFound'),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonText: this.$t('confirm'),
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
              this.imageAIArray = result
              this.typeImage = type
              this.loading = false
            }).catch(error => {
              showToast(`Error al obtener la respuesta :${error}`, 2, this)
              this.$swal({
                title: this.$t('Error'),
                text: this.$t('IA.error'),
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: this.$t('confirm'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$refs['ia-modal'].hide()
              console.error('Error al obtener la respuesta de OpenAI:', error)
            })
          } else {
            this.$swal({
              title: this.$t('Error'),
              text: this.$t('IA.fillDesc'),
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: this.$t('confirm'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }

          break
      }
    },
    sendData() {
      this.$emit('updateData', this.objectResult)
      this.$refs['ia-modal'].hide()
    },

    getNameByLocale(locale) {
      const foundItem = this.localesLanguages.find(item => item.locale === locale)
      return foundItem ? foundItem.name : null
    },
    typeText(target, fullText, typingSpeed) {
      let index = 0
      this[target] = '' // Inicializa el texto objetivo

      const interval = setInterval(() => {
        if (index < fullText.length) {
          this[target] += fullText.charAt(index)
          index += 1

          // Usar nextTick para asegurar que el DOM se haya actualizado
          this.$nextTick(() => {
            const textarea = this.$refs.typedText // Referencia al textarea

            // Comprobar si textarea existe
            if (textarea && textarea.$el) {
              textarea.$el.scrollTop = textarea.$el.scrollHeight // Mueve el scroll al final
            }
          })
        } else {
          clearInterval(interval) // Detiene el intervalo una vez que el texto está completo
        }
      }, typingSpeed)
    },
    selectImage(data, idImage) {
      const elements = document.getElementsByClassName('click-image')
      Array.from(elements).forEach(element => {
        element.classList.remove('click-image')
      })
      const image = document.getElementById(idImage)
      if (image) {
        if (!image.classList.contains('click-image')) {
          image.classList.add('click-image')
          this.objectResult = { [this.typeImage]: data }
        }
      }
    },
    convertTimeToSeconds(time) {
      // Divide la cadena en horas, minutos y segundos
      const timeParts = time.split(':') // ['0', '00', '12.92']

      // Asegúrate de que hay al menos 3 partes (hh:mm:ss)
      while (timeParts.length < 3) {
        timeParts.unshift('0') // Añade '0' para horas si no están presentes
      }

      const hours = parseInt(timeParts[0], 10) || 0 // Horas
      const minutes = parseInt(timeParts[1], 10) || 0 // Minutos
      const seconds = parseFloat(timeParts[2]) || 0 // Segundos (puede tener decimales)

      // Calcula el total de segundos y redondea hacia abajo
      return Math.floor((hours * 3600) + (minutes * 60) + seconds)
    },
    async captureThumbnail(videoElement) {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')

        // Asegurarse de que el video ha cargado el frame en el tiempo especificado
        canvas.width = videoElement.videoWidth // Establece el ancho del canvas
        canvas.height = videoElement.videoHeight // Establece la altura del canvas

        // Dibujar el frame del video en el canvas
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height)

        // Convertir el canvas a Blob (imagen)
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob) // Resolvemos la promesa con el Blob
          } else {
            reject(new Error('Error al crear el Blob'))
          }
        }, 'image/jpeg') // El formato de la imagen será JPEG
      })
    },
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
             }
         }
         `,
        })
        .then(res => {
          this.defaultLanguage = res.data.data.client.defaultLanguage
        })
        .catch(() => {
        })
    },
    generate(scene) {
      this.showMoment = true
      const titElement = document.getElementById(`#${scene.id}`)
      const tit = titElement ? titElement.value || titElement.innerText : `#${scene.id} ${this.$t('IA.generatedBy')}` // Asegúrate de obtener el valor correcto
      const start = this.convertTimeToSeconds(scene.instances[0].start)
      const end = this.convertTimeToSeconds(scene.instances[0].end)
      const duration = end - start
      const videoElement = document.getElementById(scene.id)
      const idContent = this.$route.params.id

      // Cuando el video se mueve al momento adecuado, capturamos el frame
      videoElement.addEventListener('seeked', async () => {
        // Aquí capturamos el thumbnail en el momento adecuado
        const thumbnailBlob = await this.captureThumbnail(videoElement)

        const headers = {
          'Accept-Language': this.defaultLanguage,
          'Content-Language': this.defaultLanguage,
        }
        const config = {
          timeout: 1000 * 60 * 30,
        }
        const data = new FormData()
        const encondedId = utf8ToB64(`content:${idContent}`)

        let objectVariables = []
        objectVariables = [
          { title: 'name', value: tit, type: 'String!' },
          { title: 'time', value: start, type: 'Int!' },
          { title: 'duration', value: duration === 0 ? 1 : duration, type: 'Int!' },
          { title: 'description', value: this.description, type: 'String' },
          { title: 'content', value: encondedId, type: 'ID!' },
        ]

        const variables = {}
        let mutation = 'mutation('
        let query = `{createMoment(input:{\n
        client:"${this.userData.profile.client.id}",\n
        isActive:true,\n
      `
        objectVariables.forEach(element => {
          variables[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          query += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        query += `}){
        moments{
              id
            }
        }
      }`
        query = mutation + query

        data.append('image', thumbnailBlob)
        data.append('query', query)
        data.append('variables', JSON.stringify(variables))
        axios
          .post('', data, { headers }, config)
          .then(res => {
            messageError(res, this)
            this.showMoment = false
            this.$emit('updateData')

            showToast(this.$t('success'), 1, this)
          })
          .catch(() => {
            this.showMoment = false

            showToast(this.$t('error'), 2, this)
          })
      }, { once: true })

      const simulateSeeked = () => {
        const seekedEvent = new Event('seeked')
        videoElement.dispatchEvent(seekedEvent)
      }

      // Llama a tu función cuando necesites que "seeked" ocurra
      simulateSeeked()
    },
    typeTextObject(key, fullText, typingSpeed) {
      let index = 0

      const interval = setInterval(() => {
        if (index < fullText.length) {
          const textareaID = document.getElementById(`trns_${key}`)
          if (textareaID) {
            textareaID.value += fullText.charAt(index)
            index += 1
          }
          this.$nextTick(() => {
            const textarea = this.$refs[`trns_${key}`]
            if (textarea && textarea.$el) {
              textarea.$el.scrollTop = textarea.$el.scrollHeight
            }
          })
        } else {
          clearInterval(interval)
        }
      }, typingSpeed)
    },

  },
}
</script>
<style lang="scss">
.btn-gradient {
  background: linear-gradient(58.28deg, #7a57fd -14.11%, #ff38b0 80.9%) !important;
  border: none;
  color: white !important;
  transition: background 0.3s ease;
  /* Transición suave */
}

.custom-modal-content {
  background-color: #1e2024 !important;
  /* Color de fondo al contenido del modal */
  color: #879dff;
  /* Color de texto blanco para legibilidad */
  border-radius: 8px;
  /* Borde redondeado */
  padding: 20px;
  /* Espaciado interno */
}

.custom-modal-content .modal-header,
.custom-modal-content .modal-body,
.custom-modal-content .modal-footer {
  border-radius: 80px;
  /* Borde redondeado */

  background-color: #1e2024 !important;
  /* Fondo uniforme en el header, body y footer */
  color: #879dff !important;
  /* Asegura que el texto sea blanco */
}

.custom-modal-content .modal-header {
  border-bottom: none;
  /* Opcional: eliminar el borde inferior del header */
}

.custom-modal-content .modal-footer {
  border-top: none;
  /* Opcional: eliminar el borde superior del footer */
}

/*--------------*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
  /* Duración de la transición */
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active en versiones anteriores de Vue */
  {
  opacity: 0;
}

.contenedor {
  border: 0.5px solid;
  border-radius: 10px;
  /* Redondeo de las esquinas */
  background: linear-gradient(58.28deg, #7a57fd -14.11%, #ff38b0 80.9%);
  /* Gradiente del borde */
}

.elemento {
  border: 0.5px solid;
  background-color: #1e2024;
  /* Fondo del elemento interno transparente */
  border-radius: 10px;
  /* Asegura que el interior siga el mismo redondeo */
  box-shadow: inset 0 0 0 2px transparent;
  /* Espacio que simula el borde */
}

.buttom-AI {
  /* Redondeo de las esquinas */
  background: linear-gradient(58.28deg, #7a57fd -14.11%, #ff38b0 80.9%);
  /* Gradiente del borde */
}

.text-area {
  border-radius: 10px;
  /* Asegura que el interior siga el mismo redondeo */

}

.textarea {
  width: 100%;
  /* Ocupa todo el ancho del contenedor */
  height: 100px;
  /* Altura del textarea */
  background-color: transparent;
  /* Fondo transparente para el textarea */
  border: none;
  /* Sin borde en el textarea */
  outline: none;
  /* Sin contorno al hacer clic */
  resize: none;
  /* Desactiva el redimensionamiento del textarea */
  color: #333;
  /* Color del texto */
}

.textarea::placeholder {
  color: #999;
  /* Color del placeholder */
}

#cpuBut .btn-outline-primary {
  color: white !important;
  border: 0px !important;
}

#cpuBut .dropdown-menu {
  background-color: #1e2024;
  color: white !important;
  border: 0px !important;
}

.custom-dropdown-item {
  background-color: #1e2024;
  /* Color de fondo de los items */
  color: #fff;
  /* Color del texto de los items */
}

/* Cambia el color de fondo de los items al pasar el ratón */
.custom-dropdown-item:hover {
  background-color: #141617;
  /* Color más oscuro al pasar el ratón */
  color: #879dff !important;
}

/*-----------------*/
.circular-menu {
  height: fit-content;
  position: relative;
  width: 100px;
  //height: 100px;
  //margin: 50px auto;
}

/* Botón Central */
.main-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

/* Opciones que se despliegan */
.menu-option {
  position: absolute;
  //width: 40px;
  //height: 40px;
  border-radius: 30%;
  color: white;
  display: flex;
  align-items: center;
  z-index: 1000;
  justify-content: center;
  cursor: pointer;
  transform: rotate(45deg);
  top: -2px;
  left: 30px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  /* Animación por defecto */
  opacity: 0;
  /* Las opciones comienzan ocultas */
  pointer-events: none;
  /* Las opciones no son clicables cuando están ocultas */
}

/* Clase visible que hace que aparezcan las opciones con la animación */
.menu-option.visible {
  opacity: 1;
  /* Cuando el menú está abierto, las opciones son visibles */
  pointer-events: auto;
  /* Las opciones vuelven a ser clicables */
}

.circular-menu .menu-option:hover {
  background: linear-gradient(58.28deg, #7a57fd -14.11%, #ff38b0 80.9%);
}

.custom-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 1000;
  /* Asegúrate de que esté por encima de otros elementos */
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
  /* Evita que el tooltip interfiera con el mouse */
}

.custom-tooltip.show {
  opacity: 1;
  /* Hace visible el tooltip */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  width: calc(100% - 20px);
  /* Reducir el margen para aumentar el tamaño */
  height: auto;
  padding-bottom: 56.25%;
  /* Mantener proporción 16:9 */
  position: relative;
  transition: transform 0.3s ease;
  /* Agregar transición al contenedor */
}

.image-container img {
  position: absolute;
  /* Posiciona la imagen dentro del contenedor */
  top: 0;
  /* Centra la imagen verticalmente */
  left: 0;
  /* Asegura que la imagen empiece desde la izquierda */
  width: 100%;
  /* Asegura que la imagen ocupe todo el ancho */
  height: 100%;
  /* Asegura que la imagen ocupe toda la altura */
  object-fit: cover;
  /* Mantiene la proporción de la imagen, recortando si es necesario */
  cursor: pointer;
  transition: transform 0.6s ease;
  /* Transición suave para el efecto hover */
}

.image-container:hover {
  transform: scale(1.05);
  /* Aumenta el tamaño del contenedor un 5% */
}

.image-container img:hover {
  transform: scale(1.05);
  /* Aumenta el tamaño de la imagen un 5% */
}

.click-image {
  border: 3.5px solid transparent;
  background: linear-gradient(58.28deg, #7a57fd -14.11%, #ff38b0 80.9%) border-box;
  background-size: 200% 200%;
  /* Permite que el gradiente se mueva */
  background-clip: border-box;
  animation: borderGradientMovement 1s ease-in-out infinite, grow 0.8s ease forwards;
  /* Animación continua */
  position: relative;
  filter: brightness(1.1);
  /* Aumenta el brillo */
}

.first-col-scroll {
  max-height: 640px;
  /* Define la altura máxima que puede tener el primer col */
  overflow-y: auto;
  /* Habilita el scroll vertical */
  overflow-x: hidden;
  /* Evita el scroll horizontal */
}

.colInfo {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
}

@keyframes borderGradientMovement {
  0% {
    background-position: 0% 50%;
    /* Empieza desde la esquina superior izquierda */
  }

  50% {
    background-position: 100% 50%;
    /* Se mueve hacia la derecha */
  }

  100% {
    background-position: 0% 50%;
    /* Vuelve al inicio */
  }
}
.fill-field {
  border: 2px solid transparent;
  border-radius: 0.357rem;
  background: linear-gradient(58.28deg, #7a57fd -14.11%, #ff38b0 80.9%) border-box;
  background-size: 200% 200%;
  /* Permite que el gradiente se mueva */
  background-clip: border-box;
  animation: borderGradientMovement 1s ease-in-out infinite, grow 0.8s ease forwards;
  /* Animación continua */
  position: relative;
  filter: brightness(1.1);
  /* Aumenta el brillo */
}
.field {
  border-radius: 0.357rem;
}
</style>
